module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#00247e',
    secondaryColor: '#d42e12',
    backgroundColor: 'whitesmoke',
  },
  apiKey: '89jVkly1q5sYH974Ehzh2Sb5x1ZyH75zDa28r340mx221Hfm',
};
