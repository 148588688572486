<template>
    <section>
        <HeaderCustom isHome='true'></HeaderCustom>
        <div class="container">
        <div :class="$style.header">
          <div class="row" :class="$style.columnsCustom">
            <div class="col-md-12 col-sm-12 mt-1">
              <!-- <Chart v-if="isTeacher"></Chart> -->
              <!-- <Homeworks v-if="isStudent" :lmsLoading='this.ready' :googleLoading='$store.state.googleReady'></Homeworks> -->
            </div>
          </div>
        </div>
        <br>
        <!-- <div style="margin-top: 300px;"> -->
        <div >
          <Platforms></Platforms>
        </div>
        <br>
        <!-- <Collections :isHome="true" v-if="Number(main) !== 11558 && isStudent"></Collections> -->
        <!-- <Collections :isHome="true" v-else-if="isTeacher"></Collections> -->
      </div>
      <div class="mt-3"></div>
    </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

import HeaderCustom from '@/components/HeaderCustom';
// import Chart from '@/components/Chart';
// import Homeworks from '@/components/Homeworks';
import Platforms from '@/components/Platforms';
// import Collections from '@/components/Collections';
// import serviceAuth from '../../services/auth';


export default {
    name: 'home',
    data() {
      return {
        ready: false,
        info: null,
        main: null,
      };
    },
    methods: {   
      ...mapActions(['getPackages', 'getLmsAssignments', 'getLmsIndAssignments', 'getUserDetails']),
      ...mapMutations(['setFirst']), 
    },
    async beforeMount() {
      this.getUserDetails();
      if (this.$store.state.freeContents.length > 0
      && this.$store.state.assignments.length > 0) { //eslint-disable-line
        //* Daha önceden LMS datası çekildiyse datanın gelmesi beklenmeden true dönülür
        this.ready = true;
        this.getPackages();
        this.getLmsAssignments();
        this.getLmsIndAssignments();
      } else {
        await this.getLmsAssignments();
        await this.getLmsIndAssignments();
        await this.getPackages();
        this.ready = true;
      }
    },
    components: {
      HeaderCustom,
      // Chart,
      // Homeworks,
      Platforms,
      // Collections,
    },
    computed:{
      ...mapState(['userDetails', 'username', 'password']),
      isStudent() {
        // this.main = this.userDetails.organization.find(x => x.type === 'main').id;
        // this.main = this.$store.state.userDetails.organization.find(x => x.type === 'main').id;
        const role = this.$store.state.roleType;
        if (role === 'student') {
          return true;
        }
        return false;
      },   
      isTeacher() {
        const role = this.$store.state.roleType;
        if (role === 'teacher') {
          return true;
        }
        return false;
      },
    }
}
</script>
<style src='./style.less' lang='less' module/>