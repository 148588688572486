<template>
  <div v-if="!ready">
    <InnerLoading></InnerLoading>
  </div>
  <div v-else>
    <!-- <div class="modal fade" id="tigTagModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle1">Tigtag Platformu Giriş Bilgileri</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              
              <div class="col-md-12 mt-1">
                    <div :class="$style.divPlatform">
                    <p style="margin: 5px 5px 5px 5px"> Aşağıda yer alan Kullanıcı bilgileri ile platforma giriş yapabilirsiniz.
                     Platforma geçiş yapmak için platform simgesine tıklayın.</p>

                  </div>
              </div>

              <div class="col-md-12 mt-1">
                  <div :class="$style.divPlatform" style="height:auto !important">
                    <div class="row mt-3 mb-3">
                      <div class="col-8">
                        <p style="margin: 5px 5px 5px 5px"  v-if="Number(this.grade) >= 1 && Number(this.grade) <= 4" > 
                            Username: sinavjr@eltturkey.com
                        </p>
                        <p style="margin: 5px 5px 5px 5px"  v-if="Number(this.grade) >= 5 && Number(this.grade) <= 7" >
                            Username: sinavclil@eltturkey.com
                        </p>
                        <p style="margin: 5px 5px 5px 5px"  >
                            Password: 123456
                        </p>
                      </div>
                    </div>                    
                  </div>
              </div>

              <div class="col-md-12 mt-1" v-if="Number(grade) >= 1 && Number(grade) <= 4">
                  <a  @click="gotoTigTag()" target="_blank"  data-dismiss="modal">
                    <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformBtnTigTagJunior]"></div>
                    </div>
                  </a>
              </div>

              <div class="col-md-12 mt-1" v-if="Number(grade) >= 5 && Number(grade) <= 7">
                  <a @click="gotoTigTag('clil')" target="_blank"  data-dismiss="modal">
                    <div :class="$style.divPlatform">
                      <div :class="[$style.divPlatformBtn, $style.divPlatformBtnTigTagClil]"></div>
                    </div>
                  </a>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div> -->
    
    <div class="row mt-1">
      <!-- <div class="col-md-6 mt-1" v-if="(Number(grade) <= 5 || findTeacGrade(1,5)) && school_type =='campus'">
        <a>
            <div :class="$style.divPlatform" @click="checkHll()">
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
          </div>
        </a>
      </div> -->

      <!-- <div class="col-md-6 mt-1" v-if="foundHLLStudent() == true">
        <a>
            <div :class="$style.divPlatform" @click="checkHll()">
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
          </div>
        </a>
      </div> -->
      <div class="col-md-6 mt-1" v-if="(Number(grade) >= 1 && Number(grade) <= 7 || findTeacGrade(1,7)) && school_type =='academy'">
        <a>
            <div :class="$style.divPlatform" @click="checkHll()">
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
          </div>
        </a>
      </div>

      <div class="col-md-6 mt-1" v-if="(Number(grade) >= 1 && Number(grade) <= 7 || findTeacGrade(1,7)) && school_type =='campus'">
        <a @click="checkHll()" target="_blank">
            <div :class="$style.divPlatform" @click="checkHll()">
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
          </div>
        </a>
      </div>

      <!-- <div class="col-md-6 mt-1" v-if="Number(grade) == 6 || Number(grade) == 7 || findTeacGrade(6,7)">
          <a @click="nom()" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnNewsomatic]"></div>
            </div>
          </a>          
      </div> -->

      <!-- <div class="col-md-6 mt-1" v-if="Number(grade) < 5 ">
          <a :href="`https://www.matific.com/tr/tr/login-page/`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnMatific]"></div>
            </div>
          </a>
      </div> -->


      <div class="col-md-6 mt-1" v-if="roleType =='teacher' && this.rlpUse != null">
        <a :href="`https://richmondlp.com/login`" target="blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
            </div>
          </a>
      </div>

      <template v-if="roleType =='student' && this.rlpCode != null && this.rlpCode != '###'">
        <div class="col-md-6 mt-1">
          <a data-toggle="modal" data-target="#rlpDescription">             
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
            </div>
          </a>
        </div>
      </template>
      <template v-else-if="roleType =='student' && this.rlpCode == '###'">
        <div class="col-md-6 mt-1">
          <a data-toggle="modal" data-target="#rlpDescription"> 
          <!-- <a @click="RLP()" target="_blank"> -->
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
            </div>
          </a>
        </div>
      </template>


      <!-- <div class="col-md-6 mt-1" v-if="roleType =='student' && (Number(grade) === 3) || (Number(grade) === 4)">
           <a :href="`https://www.deutschbesten.com/#/${querystingtext}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnDeutschbesten]"></div>
            </div>
          </a>
      </div> -->

      <!-- <div class="col-md-6 mt-1" v-if="roleType =='teacher' && findTeacGrade(3,4)">
           <a :href="`https://www.deutschbesten.com/#/${querystingtext}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnDeutschbesten]"></div>
            </div>
          </a>
      </div> -->

      <div class="col-md-6 mt-1" v-if="roleType =='student' && (Number(grade) === 11)">
        <!--href="https://app.newsomatic.net/login"--->
          <a @click="EFS()" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnEFS]"></div>
              <!-- <div :class="$style.txtPlatform">CLASSROOM</div> -->
            </div>
          </a>
      </div>

      <div class="col-md-6 mt-1" v-if="roleType =='teacher' &&  findTeacGrade(11,11)">
          <a @click="EFS()" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnEFS]"></div>
            </div>
          </a>
      </div>

      <!-- <div class="col-md-6 mt-1" v-if="roleType =='teacher' && findTeacGrade(8,8)">
        KAPALI href="https://app.newsomatic.net/login"
           <a :href="`https://lgsxxlarge.com/#/login/teacher/${lgsXXLquerystingtext}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnLGSXXL]"></div>
              KAPALI<div :class="$style.txtPlatform">CLASSROOM</div>
            </div>
          </a>
      </div>

      <div class="col-md-6 mt-1" v-if="roleType =='student' && Number(grade) == 8">
        KAPALI href="https://app.newsomatic.net/login"-
           <a :href="`https://lgsxxlarge.com/#/login/student/${lgsXXLquerystingtext}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnLGSXXL]"></div>
             KAPALI <div :class="$style.txtPlatform">CLASSROOM</div>
            </div>
          </a>
      </div> -->

      <!-- <template v-if="roleType == 'teacher'">
        <div class="col-md-6 mt-1" v-if="Number(grade) >= 1 && Number(grade) <= 4">
            KAPALI <a :href="`https://www.tigtagjunior.com`" target="_blank">
            <a data-toggle="modal" data-target="#tigTagModal">
            
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnTigTagJunior]"></div>
              </div>
            </a>
        </div>

        <div class="col-md-6 mt-1" v-if="Number(grade) >= 5 && Number(grade) <= 7">
            KAPALI <a :href="`https://www.tigtagworld.com/clil`" target="_blank">
            <a data-toggle="modal" data-target="#tigTagModal">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnTigTagClil]"></div>
              </div>
            </a>
        </div>
      </template> -->
      
    </div>

    <div class="modal fade" id="rlpDescription" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle1">Richmond Learning Platform Aktivasyon Adımları</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              
              <div class="col-md-12 mt-1">
                <!-- <a :href="`https://www.kidzwonder.com${this.gndkwdemo1}`" target="_blank"> -->
                  <div :class="$style.divPlatform">
                    <template v-if="this.rlpCode != null && this.rlpCode != '###'">
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">1.</b> Platforma ilk defa erişiyorsanız, öncelikle aşağıdaki <b>"Kodu Kopyala"</b> butonuna basarak kitap kodunuzu kopyalayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">2.</b> Altta gördüğünüz <b>"Richmond Learning Platform</b> simgesine tıklayınız ve siteye geçiş yapınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">3.</b> Ekranda <b>"MY PRODUCTS"</b> başlığı altında bulunan <b>"ADD ACCESS CODE"</b> butonuna basın ve kopyaladığınız kodu <b>"Please Enter a Code"</b> alanına yapıştırınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">4.</b>	Kodu yapıştırdıktan sonra sağ alt bölümde görünen <b>"Add Product"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">5.</b>	Ardından ekranda göreceğiniz <b>"START"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">6.</b>	Artık hesabınızda kitabınız aktif edilmiştir. Platformu kullanmaya başlayabilirsiniz.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">7.</b>	Sonraki erişimlerinizde sadece <b>"Richmond Learning Platform"</b> butonuna basıp platformu kullanabilirsiniz.</p>
                      <!-- <p style="margin: 5px 5px 5px 5px"> <b style="color:red">UYARI :</b> Yukarıdaki adımlar tamamlandıktan sonra bu işlemi bir daha yapmanıza gerek yoktur. 
                        Aşağıda gördüğünüz Richmond Learning Platform simgesine tıklayarak sistemi kullanmaya devam edebilirsiniz.</p> -->
                    </template>
                    <template v-else-if="this.rlpCode == '###'">
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">1.</b> Platforma ilk defa erişiyorsanız altta gördüğünüz <b>"Richmond Learning Platform</b> simgesine tıklayınız ve siteye geçiş yapınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">2.</b> Ekranda <b>"MY PRODUCTS"</b> başlığı altında bulunan <b>"ADD ACCESS CODE"</b> butonuna basın ve kitabınızda ön kapak içinde bulunan kodu <b>"Please Enter a Code"</b> alanına yazınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">3.</b>	Kodu yazdıktan sonra sağ alt bölümde görünen <b>"Add Product"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">4.</b>	Ardından ekranda göreceğiniz <b>"START"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">5.</b>	Artık hesabınızda kitabınız aktif edilmiştir. Platformu kullanmaya başlayabilirsiniz.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">6.</b>	Sonraki erişimlerinizde sadece <b>"Richmond Learning Platform"</b> butonuna basıp platformu kullanabilirsiniz.</p>
                      <!-- <p style="margin: 5px 5px 5px 5px"> <b style="color:red">UYARI :</b> Yukarıdaki adımlar tamamlandıktan sonra bu işlemi bir daha yapmanıza gerek yoktur. 
                        Aşağıda gördüğünüz Richmond Learning Platform simgesine tıklayarak sistemi kullanmaya devam edebilirsiniz.</p> -->
                    </template>


                  </div>
                <!-- </a> -->
              </div>

              <div class="col-md-12 mt-1" v-if="this.rlpCode != null && this.rlpCode != '###'">
                  <div :class="$style.divPlatform" style="height:auto !important">
                    <div class="row mt-3 mb-3">
                      <div class="col-8">
                        <p style="margin: 5px 5px 5px 5px" ref="code" v-on:focus="$event.target.select()" >{{rlpCode}}</p>
                        <input type="hidden" id="rlp-code" :value="rlpCode">
                      </div>
                      
                      <div class="col-4">                      
                        <button type="button" @click="copyCode()" class="btn btn-primary">Kodu Kopyala</button>
                      </div>
                    </div>                    
                  </div>
              </div>
              <div class="col-md-6 mt-1">
                <!-- <a data-toggle="modal" data-target="#rlpDescription"> -->
                  <a @click="RLP()" target="_blank" data-dismiss="modal">
                  
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>

              <div v-if="this.rlpCode != null && this.rlpCode != '###'" class="col-md-6 mt-1">
                <a :href="`https://www.youtube.com/embed/5VS7u30m_Gc?si=2K_RXLa5Y_u_L64y`" target="blank">
                 
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLPYoutube]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>

              <div v-if="this.rlpCode == '###'" class="col-md-6 mt-1">
                <a :href="`https://www.youtube.com/embed/O2x4o_LeEqY?si=ctl_x10KvOEDzTVe`" target="blank">
                 
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLPYoutube]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div v-else-if="roleType === 'teacher' && userDetails.lang === 'en'" class="row mt-1">
    <div class="col-md-12 mt-1" v-if="levelName === 'İlkokul'">
      <a href="https://classroom.google.com" target="_blank">
        <div :class="$style.divPlatform">
          <div :class="[$style.divPlatformBtn, $style.divPlatformBtnGoogle]"></div>
          <div :class="$style.txtPlatform">CLASSROOM</div>
        </div>
      </a>
    </div>
    <div class="col-md-6 mt-1" v-if="levelName === 'Ortaokul'">
      <a :href="`http://admin.agkcity.com/login${querystingtext}`" target="_blank">
        <div :class="$style.divPlatform">
          <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAgk]"></div>
          <div :class="$style.txtPlatform">AGK CITY</div>
        </div>
      </a>
    </div>
    <div class="col-md-6 mt-1" v-if="levelName === 'Ortaokul'">
      <a href="https://classroom.google.com" target="_blank">
        <div :class="$style.divPlatform">
          <div :class="[$style.divPlatformBtn, $style.divPlatformBtnGoogle]"></div>
          <div :class="$style.txtPlatform">CLASSROOM</div>
        </div>
      </a>
    </div>
    <div class="col-md-12 mt-1" v-if="levelName === 'Lise'">
      <a :href="`http://gundogdu.ueslms.com/login${querystingtext}`" target="_blank">
        <div :class="$style.divPlatform">
          <div :class="[$style.divPlatformBtnTeachersResources, $style.divPlatformTeacherContents]"></div>
        </div>
      </a>
    </div>
  </div> -->

  
</template>

<script>
import swal from 'sweetalert';
// import userService from '@/services/user';
import InnerLoading from '@/components/InnerLoading';
// import axios from 'axios';
import { mapState } from 'vuex';
import CryptoJS from 'crypto-js';

export default {
  name: 'platforms',
  data() {
    return {
      rlpUse: null,
      rlpCode: null,
      grade: '',
      teacherGrades:[],
      level: '',
      roleType: '',
      school_type: '',
      querystingtext: '',
      lgsXXLquerystingtext:'',
      kwquerystingtext: '',
      hexaquerystingtext: '',
      ready: true,
      // hllStudent:[
      //   '41491560796',
      //   '24470109018',
      //   '26762032738',
      //   '52642189254',
      //   '37300681806',
      //   '54271115826',
      //   '46388006678',
      //   '36448710430',
      //   '37399678664',
      //   '43537476986',
      //   '35077755706',
      //   '55849063662',
      //   '28828963758',
      //   '51826826558',
      //   '64342407538',
      //   '51700201488',
      //   '55402078652',
      //   '43096488260',
      //   '39250616518',
      //   '55663069314',
      //   '39874595954',
      //   '52117209074',
      //   '52225184324',
      //   '39658603370',
      //   '33769815394',
      //   '31375879182',
      //   '66088349318',
      //   '45439427356',
      //   '21956193578',
      //   '26081055692',
      //   '54619104628',
      //   '18227944670',
      //   '13670485510',
      //   '36061744898',
      //   '34792765014',
      //   '26477042428',
      //   '48886295370',
      //   '00032384744'
      // ]

    };
  },
  components: {
    InnerLoading,
  },
  computed: {
    ...mapState(['userDetails', 'username', 'password']),
    levelName() {
      return this.$store.state.userDetails.organization.find(x => x.type === 'level').name;
    },
  },
  beforeMount() {
    this.setData();
  },
  watch: {
    userDetails() {
      this.setData();
    },
  },
  methods: {
    copyCode(){
      let testingCodeToCopy = document.querySelector('#rlp-code')
      testingCodeToCopy.setAttribute('type', 'text') 
      testingCodeToCopy.select()

      // try {
        document.execCommand('copy');
        // var successful = document.execCommand('copy');
        // var msg = successful ? 'successful' : 'unsuccessful';
      //   alert('Testing code was copied ' + msg);
      // } catch (err) {
      //   alert('Oops, unable to copy');
      // }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },

    gotoTigTag(lnk){
      if (lnk == 'clil'){
        window.open('https://www.tigtagworld.com/clil');
      } else {
        window.open('https://www.tigtagjunior.com');
      }
    },

    // foundHLLStudent(){
    //   const uDetails = this.userDetails;
    //   const tckn = uDetails.email;
    //   const foundStudent = this.hllStudent.filter(x => x == tckn);

    //   if (foundStudent.length > 0){
    //     return true
    //   } else {
    //     return false
    //   }
    // },

    async RLP() {
      // console.log('sdsds')
      try {
        this.ready = false;
        const secretKey = "VswFYGzh2k45N7Q8R9@TBuCVexFY.G2J3K4N6p7Q%9SATB-VdWeXFZHJ3m5N";
        const userData = {}
        const classData = {}
        if (this.userDetails) {
          userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
          userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.username = this.userDetails.email;
          userData.role_type = this.userDetails.role_type;
          classData.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
          classData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
          userData.class = [classData];
          // userData.user_id = this.userDetails.user_id;

        }

        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        this.ready = true;
        window.open('https://service.ueslms.com/#/rlp/?sso=' + btoa(ciphertext));
        

      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },

    async EFS() {
      // console.log('sdsds')
      try {
        this.ready = false;
        const secretKey = "Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy";
        const userData = {}
        if (this.userDetails) {
          const classData = {}
          userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
          userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.username = this.userDetails.email;
          userData.role_type = this.userDetails.role_type;
          classData.grade = 0
          classData.branch = ''
          if (this.roleType=='student'){
            if (this.userDetails.organization.find(x => x.type === 'grade')){
              const fullClass = this.userDetails.organization.find(x => x.type === 'grade').name.split('-')
              classData.grade = fullClass[0]
              classData.branch = fullClass.length == 3 ? fullClass[1]+'-'+ fullClass[2]: fullClass[1]
            }
            userData.class = [classData];

          } else {

            const class2=[]             
            const teacherGrades = this.userDetails.organization.filter((x) => x.type === "grade")
            for (let key in teacherGrades){ 
              const fullClass = teacherGrades[key].name.split('-')
              const classData = {}
              classData.grade = fullClass[0]
              classData.branch = fullClass.length == 3 ? fullClass[1]+'-'+ fullClass[2]: fullClass[1]
              class2.push(classData)
              // userData.class.push(classData)
            }
            userData.class = class2
          }
          //classData.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
          //classData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
         

        }
        


        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        this.ready = true;
        window.open('https://service.ueslms.com/#/efs/?sso=' + btoa(ciphertext));
        

      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },

    async checkHll() {
      try {
        this.ready = false;
        const userDetails = await this.userDetails;
        const userId = userDetails.user_id;
        const firstName = userDetails.first_name;
        const lastName = userDetails.last_name;
        // const className = userDetails.organization.find(x => x.type === 'grade').name;
        const userType = userDetails.role_type;
        // const campusId = userDetails.organization.find(y => y.type === 'campus').id;
        const key = userDetails.organization.find(y => y.type === 'campus').hll_company_code;
        // console.log('key', key);
        // const key = obj.find(x => x.schoolId === campusId).companyKey;

        if(userDetails.role_type === "student"){
          const className = encodeURIComponent(userDetails.organization.find(x => x.type === 'grade').name);
          window.go_hll(userId, userId, firstName, lastName, className, userType, key);
        }else if(userDetails.role_type === "teacher"){
          const classes = [];
          await userDetails.organization.filter(x => x.type === 'grade').forEach((item) => {
            classes.push(encodeURIComponent(item.name))
          });
          window.go_hll(userId, userId, firstName, lastName, classes, userType, key);
        }
        setTimeout(() => {
          this.ready = true;
        }, 1000);
      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },
    // nom() {
      
    //     // this.newsomaticSchool = this.userDetails.compass_school;
    //     this.newsomaticUser = this.userDetails.compass_username != null ? atob(this.userDetails.compass_username) : '';
    //     this.newsomaticPass = this.userDetails.compass_password != null ? atob(this.userDetails.compass_password) : '';

    //   if(this.roleType === 'teacher'){
    //     window.open('https://app.newsomatic.net/login', '_blank');
    //   }else{
    //     if(this.newsomaticUser != ''){
    //       swal({
    //         title: 'News-o-Matic',
    //         html:`        
    //         <div class="col-md-12 mt-2 text-center" style="display:table-cells" v-else> <!-- Eğer uesDigital kodu girilmişse -->
    //                     <div class="alert alert-info" role="alert">
    //                       Aşağıdaki kullanıcı bilgileri ile Go to News-o-Matic linkine tıklayarak hesabınıza giriş yapabilirsiniz.
    //                     </div>
    //                     <table class="table table-sm table-striped">
    //                       <tbody>
    //                         <tr>
    //                           <td class="table-primary"><b>User name</b></td>
    //                           <td class="table-light"><b>${this.newsomaticUser}</b></td>
    //                         </tr>
    //                         <tr>
    //                           <td class="table-primary"><b>Password</b></td>
    //                           <td class="table-light"><b>${this.newsomaticPass}</b></td>
    //                         </tr>
    //                       </tbody>
    //                     </table>
    //                 </div>`,
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Go to News-o-matic',
    //       }).then((result) => {
    //         if (result.value) {
    //           window.open('https://app.newsomatic.net/login', '_blank');
    //         }
    //       });

    //     }else {
    //       swal({
    //         title:'Uyarı!',
    //         text: 'Kullanıcı bilgileriniz en kısa sürede burada görünecektir...',
    //         confirmButtonText: 'Kapat',
    //       });
    //     }


    //   }

    // },

    nom() {
      // console.log('sdsds')
      try {
        this.ready = false;
        const secretKey = "Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy";
        const userData = {}
        if (this.userDetails) {
          userData.username = this.userDetails.email;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.role_type = this.userDetails.role_type;
          userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
          userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;
          if (this.roleType == 'student'){
            userData.class = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
            userData.branch = this.userDetails.organization.find(x => x.type === 'grade') 
            ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1]+'-'+this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[2] 
            : '';

          } else {
            let _classes = []
            const teacherGrades = this.userDetails.organization.filter((x) => x.type === "grade")
            for (let key in teacherGrades){
              _classes.push(teacherGrades[key].name)
            }
            userData.classes = _classes
          }
          //userData.user_id = this.userDetails.user_id;
          userData.reading_level= 1;
        }
          // console.log(userData)

        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        this.ready = true;
        window.open('https://service.ueslms.com/#/nom/?sso=' + btoa(ciphertext));
        

      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },

    setData() {
      if (this.userDetails) {
        this.level = this.userDetails.organization.find(x => x.type === 'level').name;
        this.grade = this.userDetails.organization.find(x => x.type === 'grade')
          ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
        this.roleType = this.userDetails.role_type;

        const teacherGrades = this.userDetails.organization.filter((x) => x.type === "grade")
        for (let key in teacherGrades){
          this.teacherGrades.push(teacherGrades[key].name.split("-")[0])
        }

        this.rlpUse = this.userDetails.organization.find(x => x.type === 'campus').rlp_institute_code
        this.rlpCode = this.userDetails.rlp_code ? this.userDetails.rlp_code : null

        this.school_type = this.userDetails.organization.filter(x => x.type === 'campus')[0].school_type;
        this.querystingtext = `?username=${btoa(this.username)}&password=${btoa(this.password)}`;
        this.lgsXXLquerystingtext = `?username=${btoa(this.username)}&password=${btoa(this.password)}`;
        if (this.userDetails.lang === 'en') {
          this.hexaquerystingtext = `?username=${btoa('steve.jobs')}&password=${btoa('090807')}`;
        } else {
          this.hexaquerystingtext = this.querystingtext;
        }

      }
      this.kwquerystingtext = `?username=${btoa('uesnlkw_teacher')}&password=${btoa('123456')}`;

    },

    findTeacGrade(grade1, grade2){
        const grade = this.teacherGrades.filter((x) => (x >= grade1 && x <= grade2))
        if (grade.length > 0) return true; else return false;
    },
  },
};
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
  a {
    text-decoration: none;
    color: black;
  }
</style>
